import React from 'react'
import cn from 'classnames'
import * as _ from '@technically/lodash'

import RenderEngine from '~p/client/render3d/components/RenderEngine'

import Renderer from '~rawlings/client/components/Renderer'
import Icon from '~rawlings/client/components/Icon'
import ViewSwitcher from '~rawlings/client/components/ViewSwitcher'

import viewAngles from '../../viewAngles'
import createSceneContext from '../createSceneContext'
import updateScene from '../updateScene'
import { VIEW_DEF_DICT } from '../../common/sheets'

import store from '../../client/store'
import controlTree from '../../client/controlTree'

class Render3d extends React.Component {
  state = {
    isLoading: true,
  }

  setPreviewGeneratorWarm = (generatorWarm) => {
    if (!generatorWarm) {
      this.props.setPreviewGenerator(undefined)
      return
    }

    const generator = (viewNames) => {
      const expandedRecipe = controlTree.getExpandedRecipeNested(
        store.getState(),
      )
      const { viewDefGroupId } = expandedRecipe.product.model.props

      const cameraDefs = _.mapValues(
        _.keyBy(
          _.filter(VIEW_DEF_DICT, { groupId: viewDefGroupId }),
          'viewName',
        ),
        (viewDef, viewName) => ({
          ...viewDef.cameraDef,
          ...viewAngles[viewName],
        }),
      )

      if (!Object.keys(cameraDefs).length) {
        throw new Error('This category has no views!')
      }

      return generatorWarm(
        this.props.sceneDef,
        cameraDefs,
        createSceneContext,
        updateScene,
      )(viewNames)
    }

    this.props.setPreviewGenerator(generator)
  }

  setLoading = (isLoading) => {
    if (isLoading !== this.state.isLoading) {
      this.setState({ isLoading })
    }
  }

  render() {
    return (
      <div>
        {!this.props.isFocusMode && (
          <a
            className="preview-focus"
            onClick={(ev) => {
              ev.preventDefault()
              this.props.setFocusMode(true)
            }}
          >
            <Icon name="magnifier" />
          </a>
        )}
        <div
          className={cn('preview', {
            'is-focus': this.props.isFocusMode,
            'is-covering-background': true,
          })}
        >
          <ViewSwitcher
            viewAngles={viewAngles}
            activeView={this.props.activeView}
            setFocusMode={this.props.setFocusMode}
            isFocusMode={this.props.isFocusMode}
          >
            <Renderer isLoading={this.state.isLoading}>
              <RenderEngine
                expandedRecipe={this.props.expandedRecipe}
                sceneDef={this.props.sceneDef}
                cameraDef={this.props.cameraDef}
                createSceneContext={createSceneContext}
                updateScene={updateScene}
                setLoading={this.setLoading}
                setPreviewGeneratorWarm={this.setPreviewGeneratorWarm}
              />
            </Renderer>
          </ViewSwitcher>
        </div>
      </div>
    )
  }
}

export default Render3d
